import classNames from 'classnames';
import * as React from 'react';
import './text-input.scss';

type HTMLInputProps = Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange' | 'value'>;
type TextInputProps = HTMLInputProps & {
  caption?: string;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  value: string;
};
export const TextInput = (props: TextInputProps) => {
  const { caption, readOnly, placeholder, onChange, value, ...textInputProps } = props;
  return (
    <div
      className={classNames('dragonfruit-input-wrapper', {
        readonly: readOnly,
      })}
    >
      {caption && (
        <label
          className="label text-style-caption"
        >
          {caption}
        </label>
      )}
      <div
        className={classNames('text-field', `text-field-input`, {
          readonly: readOnly,
        })}
      >
        <div className="before-input-and-input" data-testid="text-field-inner">
          <input
            {...textInputProps}
            className={classNames('text-style-body', `text-style-body-text`)}
            value={value}
            onChange={(event): void => {
              onChange?.(event.target.value ?? '');
            }}
            placeholder={placeholder}
            readOnly={readOnly}
            aria-label={caption}
          />
        </div>
      </div>
    </div>
  )
}
