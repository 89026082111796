import {GetUserInfoResponse, Info, Version} from '../../../models';

import requests from './requests';

let info: Promise<Info>; // we cache this globally rather than in localStorage so it is request once per page refresh

export const InfoService = {
    async getInfo() {
        if (info) {
            const information = await info;
            return information;
        }
        let resolve: (value: Info) => void;
        info = new Promise<Info>(res => { resolve = res; });
        let newInfo: Info;
        try {
            const infoResponse = await requests.get(`api/v1/info`)
            newInfo = infoResponse.body as Info;
        } catch (error: unknown) {
            console.error('Error getting info:', error);
            newInfo = { modals: { string:  false}, columns: []}
        }
        resolve(newInfo);
        return newInfo;
    },

    async getVersion() {
        let version: Version;
        try {
            const versionResponse = await requests.get(`api/v1/version`);
            version = versionResponse.body as Version;
        } catch (error: unknown) {
            console.error('Error getting version:', error);
            version = {version: 'unknown'};
        }
        return version;
    },

    async getUserInfo() {
        let userInfoResponse: GetUserInfoResponse;
        try {
            userInfoResponse = await requests.get(`api/v1/userinfo`).then(res => res.body as GetUserInfoResponse);
        } catch (error: unknown) {
            console.error('Error getting user info:', error);
            return {};
        }
        return userInfoResponse;
    },

    collectEvent(name: string) {
        return requests.post(`api/v1/tracking/event`).send({name});
    }
};
