import React from 'react'

function WorkflowIcon() {
    return (
        <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
            <path id="Vector" d="M11.8333 5.41667V0.75H7.74999V2.5H5.41666L5.41666 5.41667H4.24999L4.24999 3.66667H0.166656L0.166656 8.33333H4.24999V6.58333H5.41666V9.5H7.74999V11.25H11.8333V6.58333H7.74999V8.33333H6.58332L6.58332 3.66667L7.74999 3.66667L7.74999 5.41667H11.8333Z" />
            </g>
        </svg>
    )
}

export default WorkflowIcon
