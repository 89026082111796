import {ObjectMeta} from 'argo-ui/src/models/kubernetes';
import * as React from 'react';
import {WorkflowStatus} from '../../../../models';
import {Notice} from '../../../shared/components/notice';
import {Phase} from '../../../shared/components/phase';
import {WorkflowDag} from '../workflow-dag/workflow-dag';
import { useWorkflow } from '../workflow-details/workflow-details';

interface Props {
    workflowMetadata: ObjectMeta;
    workflowStatus: WorkflowStatus;
    selectedNodeId: string;
    nodeRightClicked: (nodeId: string) => void;
}

export function WorkflowPanel(props: Props) {
    if (!props.workflowStatus.nodes && props.workflowStatus.phase) {
        return (
            <div className='argo-container'>
                <Notice>
                    <Phase value={props.workflowStatus.phase} />: {props.workflowStatus.message}
                </Notice>
            </div>
        );
    }

    const workflow = useWorkflow();
    const handleNodeClick = React.useCallback((nodeId: string) => {
        if (!workflow.status || !workflow.status.nodes || !workflow.status.nodes[nodeId] || !workflow.status.nodes[nodeId].outputs || !workflow.status.nodes[nodeId].outputs.parameters) {
            return;
        }
        const linkParameter = workflow.status.nodes[nodeId].outputs.parameters.find(param => param && (param.name === 'link' || param.name == 'url') && param.value);
        if (!linkParameter) {
            return;
        }
        window.open(linkParameter.value, '_blank', 'noopener noreferrer')
    }, []);

    const handleNodeRightClick = React.useCallback((nodeId: string) => {
        props.nodeRightClicked(nodeId);
    }, [props.nodeRightClicked]);

    return (
        <WorkflowDag
            workflowName={props.workflowMetadata.name}
            nodes={props.workflowStatus.nodes}
            artifactRepositoryRef={props.workflowStatus.artifactRepositoryRef}
            selectedNodeId={props.selectedNodeId}
            nodeClicked={handleNodeClick}
            nodeRightClicked={handleNodeRightClick}
        />
    );
}
